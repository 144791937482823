<template>
  <div class="bgconten">
    <div class="molcenter">
      <div class="mol_top">
        <div>
          <div class="tys">支</div>
          <div class="tyl">50</div>
        </div>
        <div>The amount you need to pay</div>
      </div>
      <div class="mol_center">Select an option to pay(UPI)</div>
      <div class="button_mol">
        <div v-for="(items,index) in queryObject" :key="items.ifcodeName" class="mol_for"
             :class="selectIndex === index ? 'selectPlugin' :''"
             @click="selectIndex = index">
          <image :src="items.imgL" mode="widthFix"/>
          <div>{{ items.ifcodeName }}</div>
          <!--          <div class="qy"></div>-->
        </div>
      </div>
      <a href="javascript:;" v-loading.fullscreen.lock="fullscreenLoading" @click="startApp" class="pay_but">Pay 支
        50</a>
    </div>
  </div>
</template>

<script>
import {queryH5PluginOrders} from '@/api/api'

export default {
  data() {
    return {
      fullscreenLoading: false,
      queryObject: [],
      selectIndex: 1
    }
  },
  mounted() {
    // 从数据库中获取 这个商户开通了哪些h5 支付插件
    const _this = this
    // 获取到订单号
    _this.orderId = this.$route.params.id
    console.log(_this.orderId)
    queryH5PluginOrders(_this.orderId).then(res => {
      console.log(res)
      _this.queryObject = res
    })
  },
  methods: {
    startApp() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 5000);
    },
    pluginIn(event) {
      console.log(event)
    }
  },
}
</script>

<style type="text/css">
a {
  text-decoration: none;
}

.bgconten {
  height: 17vh;
  /* background:linear-gradient(to bottom right, #ce11cc  30%, #118ec8 ); */
  background-image: linear-gradient(to bottom right, #4262FD, #00aefd);
  padding: 10Px;
}

.molcenter {
  margin: 10Px;
  /* height:70vh; */
  background-color: rgba(255, 255, 255);
  box-shadow: 10Px 5Px 20Px rgba(0, 0, 0, 0.1);
  border-radius: 10Px;
  padding: 20Px 18Px 40Px;
}

.mol_top {
  padding: 0 20Px;
}

.mol_top > div:nth-child(1) {
  display: flex;
  align-items: baseline;
  color: #724EC6;
}

.tys {
  font-size: 15Px;
}

.tyl {
  margin-left: 2Px;
  font-size: 27Px;
  vertical-align: middle;
}

.mol_top > div:nth-child(1) image {
  margin-right: 10Px;
}

.mol_top > div:nth-child(2) {
  font-size: 15Px;
  color: #d5d2d2;
  text-align: left;
}

.mol_center {
  text-align: left;
  font-size: 18Px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10Px;
}

.button_mol {
  padding: 30Px 0Px;
}

.button_mol {
  font-size: 15Px;
  font-weight: 600;
}

.mol_for {
  display: flex;
  align-items: center;
  padding: 20Px;
  border-bottom: 1Px solid #d5d5d5;
  border-radius: 8Px;
  position: relative;
}

.mol_for image {
  width: 90Px;
  border-radius: 15Px;
}

.qy {
  width: 30Px;
  height: 30Px;
  border-radius: 50%;
  border: 1Px solid #e7e4e4;
  position: absolute;
  right: 30Px;
}

.selectPlugin {
  background-color: rgb(182, 232, 254);
}

.pay_but {
  display: inline-block;
  border-radius: 10Px;
  font-size: 20Px;
  width: 100%;
  line-height: 50Px;
  background-color: #0288F9;
  color: #fff;
  margin-top: 30Px;
}
</style>
